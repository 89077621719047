import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Routes, Route } from 'react-router-dom';
import Sidenav from './LeftMenu';
import AdminHeader from "./AdminHeader";
import Category from './Category';  // Assuming default export from './Category'
import Subcategory from './Subcategory';  // Assuming default export from './Subcategory'
import Addproduct from './Addproduct';  // Assuming default export from './Addproduct'
import Colormanager from './Colormanager';  // Assuming default export from './Colormanager'
import ProductList from './ProductList';  // Assuming default export from './ProductList'
import EditProduct from './EditProduct';  // Assuming default export from './EditProduct'
import Addcoupon from './Addcoupon';
import Dashboardcounts from './Dashboardcounts';
import Comboproduct from './Comboproduct';
import Combolist from './Combolist';
import EditCombo from './Editcombo';
import AddBanner from './Addbanner';
import BannerList from './Bannerlist';
import AddCustomBanner from './Addcustombanner';
import CustomBannerList from './Custombannerlist';
import AddChildProduct from './Addchildproduct';
import ChildProductList from './Childproductlist';
import AddFabricType from './AddFabricType';
import FabricTypeList from './FabricTypeList';
import EditFabricType from './EditFabricType';
import AddFitType from './AddFitType';
import FitTypeList from './FitTypeList';
import EditFitType from './EditFitType';


function Dashboard() {
    return (

        <div className="container-scroller">
            <AdminHeader />
            <Container fluid>
                <Row>
                    <Col md={2}>
                        <Sidenav />
                    </Col>
                    <Col md={10}>
                        <Routes>
                            <Route exact path="/category" element={<Category />} />
                            <Route path="/addproduct" element={<Addproduct />} />
                            <Route path="/ProductList" element={<ProductList />} />
                            <Route path="/dashboardcounts" element={<Dashboardcounts />} />
                            <Route path="/colormanager" element={<Colormanager />}/>
                            <Route path="/EditProduct/" element={<EditProduct />} />
                            <Route path="/addcoupon" element={<Addcoupon />} />
                            <Route path="/addcombo" element={<Comboproduct />} />
                            <Route path="/Combolist" element={<Combolist />} />
                            <Route path="/editcombo" element={<EditCombo />} />
                            <Route path="/addbanner" element={<AddBanner />} />
                            <Route path="/bannerlist" element={<BannerList />} />
                            <Route path="/custombanner" element={<AddCustomBanner />} />
                            <Route path="/custombannerlist" element={<CustomBannerList />} />
                            <Route path="/addchildproduct" element={<AddChildProduct />} />
                            <Route path="/childproductlist" element={<ChildProductList /> }/>
                            <Route path="/addfabrictype" element={<AddFabricType /> }/>
                            <Route path="/fabrictypelist" element={<FabricTypeList />} />
                            <Route path="/editfabrictype/:fabricId" element={<EditFabricType /> }/>
                            <Route path="/addfittype" element={<AddFitType />} />
                            <Route path="/fittypelist" element={<FitTypeList />} />
                            <Route path="/editfittype/:fitId" element={<EditFitType />} />
                        </Routes>
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

export default Dashboard;
