import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CSVUpload from './CSVUpload';
import "../assets/css/addproduct.css";

function AddProduct() {
    const [itemName, setItemName] = useState("");
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [dimensions, setDimensions] = useState("");
    const [price, setPrice] = useState("");
    const [discount, setDiscount] = useState("");
    const [stockQty, setStockQty] = useState("");
    const [isFeatured, setIsFeatured] = useState(false);
    const [Isreturnable, setIsReturnable] = useState(false);
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState("");
    const [sampleImages, setSampleImages] = useState(new Array(4).fill(null));
    const [sellingPrice, setSellingPrice] = useState("");
    const [tax, setTax] = useState("");
    const [packageWeight, setPackageWeight] = useState("");
    const [packageHeight, setPackageHeight] = useState("");
    const [packageLength, setPackageLength] = useState("");
    const [packageWidth, setPackageWidth] = useState("");
    // const [fabricType, setFabricType] = useState("");
    const [fabricTypes, setFabricTypes] = useState([]);
    const [fabricTypeID, setFabricTypeID] = useState('');

    const [manufacturedBy, setManufacturedBy] = useState("");
    const [washCare, setWashCare] = useState("");
    // const [fit, setFit] = useState("");
    const [fitID, setFitID] = useState("");
    const [fits, setFits] = useState([]);
    const [gender, setGender] = useState("");
    const [colorID, setColorID] = useState("");
    const [skqnumber, setSkqnumber] = useState("");
    const [isAvailable, setIsAvailable] = useState(false);
    const [stock, setStock] = useState({
        xs: "",
        s: "",
        m: "",
        l: "",
        xl: "",
        xxl: "",
    });

    const [videoUrl, setVideoUrl] = useState("");
    const [videoFile, setVideoFile] = useState(null); // New state for video file
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [colors, setColors] = useState([]);
    const authorid = localStorage.getItem("authorid")
    useEffect(() => {
        fetchCategories();
        fetchColors();
        fetchFits();
        fetchFabricTypes();
    }, []);

    const fetchCategories = async () => {
        try {
            const token = localStorage.getItem("responsetoken");
            const url = `https://api.rollerfashions.com/api/Category/List_Category`;


            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };
    debugger;
    const fetchSubCategories = async (categoryID) => {
        try {
            const token = localStorage.getItem("responsetoken");
            const url = `https://api.rollerfashions.com/api/SubCategory/List_SubCategory?Category_ID=${categoryID}`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            setSubCategories(response.data);
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };

    const fetchColors = async () => {
        try {
            const token = localStorage.getItem("responsetoken");
            const url = `https://api.rollerfashions.com/api/Color/List_Color`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            setColors(response.data);
        } catch (error) {
            console.error("Error fetching colors:", error);
        }
    };
    debugger;
    const fetchFits = async () => {
        try {
            const token = localStorage.getItem("responsetoken");
            const url = `https://api.rollerfashions.com/api/Fit_type/List_fittype`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (response.data && Array.isArray(response.data)) {
                setFits(response.data);
            } else {
                console.error("Unexpected data format:", response.data);
                setFits([]);  // Fallback to empty array if response is invalid
            }
            console.log(response.data); // To verify data structure
        } catch (error) {
            console.error("Error fetching fits:", error.response ? error.response.data : error.message);
            setFits([]);  // Ensure fits is an empty array on error
        }
    };
    const fetchFabricTypes = async () => {
        try {
            const token = localStorage.getItem("responsetoken");
            const url = `https://api.rollerfashions.com/api/Fabric_Type/List_fabrictype`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (response.data && Array.isArray(response.data)) {
                setFabricTypes(response.data);
            } else {
                console.error("Unexpected data format:", response.data);
                setFabricTypes([]);  // Fallback to empty array if response is invalid
            }
            console.log(response.data); // To verify data structure
        } catch (error) {
            console.error("Error fetching fabric types:", error.response ? error.response.data : error.message);
            setFabricTypes([]);  // Ensure fabricTypes is an empty array on error
        }
    };


    debugger;
    const resetForm = () => {
        setItemName("");
        setCategory("");
        setSubCategory("");
        setDimensions("");
        setPrice("");
        setDiscount("");
        setStockQty("");
        setIsFeatured(false);
        setIsReturnable(false);
        setDescription("");
        setImage(null);
        setImageName("");
        setSampleImages(new Array(4).fill(null));
        setSellingPrice("");
        setTax("");
        setPackageWeight("");
        setPackageHeight("");
        setPackageLength("");
        setPackageWidth("");
        setFabricTypes("");
        setManufacturedBy("");
        setWashCare("");
        setFits("");
        setGender("");
        setColorID("");
        setSkqnumber("");
        setIsAvailable(false);
        setStock({
            xs: "",
            s: "",
            m: "",
            l: "",
            xl: "",
            xxl: "",
        });
        setVideoUrl("");
        setVideoFile(null);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        debugger;
        const formData = new FormData();
        formData.append('name', itemName);
        formData.append('category_ID', category);
        formData.append('subcategory_ID', subCategory);
        formData.append('dimensions', dimensions);
        formData.append('price', price);
        formData.append('discount', discount);
        formData.append('stockQty', stockQty);
        formData.append('isfeature', isFeatured);
        formData.append('Isreturnable', Isreturnable);
        formData.append('description', description);
        formData.append('selling_price', sellingPrice);
        formData.append('tax', tax);
        formData.append('packageWeight', packageWeight);
        formData.append('packageheight', packageHeight);
        formData.append('packagelength', packageLength);
        formData.append('packagewidth', packageWidth);
        formData.append('fabrictype', fabricTypes);
        formData.append('manufaturedby', manufacturedBy);
        formData.append('washcare', washCare);
        formData.append('fit', fits);
        formData.append('gender', gender);
        formData.append('color_ID', colorID);
        formData.append('skq_number', skqnumber);
        formData.append('isavailable', isAvailable);
        formData.append('xs', stock.xs);
        formData.append('s', stock.s);
        formData.append('m', stock.m);
        formData.append('l', stock.l);
        formData.append('xl', stock.xl);
        formData.append('xxl', stock.xxl);

        // Add stock sizes
        // Object.keys(stock).forEach(size => {
        //     formData.append(`stock.${size}`, stock[size]);
        // });
        const handleStockChange = (e, size) => {
            const value = e.target.value;
            setStock(prevStock => ({
                ...prevStock,
                [size]: value
            }));
        };

        // Add display_image if it exists
        if (image) {
            formData.append('display_image', image); // Append the file object directly
        }

        // Add sample_images
        sampleImages.forEach((sampleImage, index) => {
            if (sampleImage) {
                formData.append(`Sample_image${index + 1}`, sampleImage); // Append the file object directly
            }
        });

        // Add video file or URL
        if (videoFile) {
            formData.append('videoFile', videoFile); // Append the file object directly
        } else if (videoUrl) {
            formData.append('video_url', videoUrl);
        }

        const token = localStorage.getItem("responsetoken");
        formData.append('author_ID', authorid);

        // Debugging: Print form data
        for (let pair of formData.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`);
        }

        try {
            const response = await axios.post('https://api.rollerfashions.com/api/Product/Create_Product', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                }
            });
            console.log(response.data);
            toast.success('Product created successfully');
            resetForm();
            // Reset CSVUpload component
            if (csvUploadRef.current) {
                csvUploadRef.current.reset();
            }
        } catch (error) {
            // Use the error message from the response if available, otherwise a generic message
            const errorMessage = error.response?.data?.message || 'Failed to create product';

            toast.error(errorMessage);
        }

    };





    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size <= 102400) { // Check if file size is <= 100KB (102400 bytes)
                setImage(file);
                setImageName(file.name);
            } else {
                toast.error("Image size should be less than or equal to 100KB");
            }
        }
    };

    const handleSampleImageUpload = async (e, index) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size <= 102400) { // Check if file size is <= 100KB (102400 bytes)
                const updatedSampleImages = [...sampleImages];
                updatedSampleImages[index] = file;
                setSampleImages(updatedSampleImages);
            } else {
                toast.error("Image size should be less than or equal to 100KB");
            }
        }
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = error => reject(error);
    });


    const handleVideoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setVideoFile(file);
        }
    };

    const handleStockChange = (e, size) => {
        const value = e.target.value;
        setStock(prevStock => ({
            ...prevStock,
            [size]: value
        }));
    };
    const csvUploadRef = useRef(null); // Create a ref for CSVUpload
    return (
        <div className="container-fluid addproduct addproduct-container">

            <div className="card addproduct-card">
                <h2 className='coupon-title'>Add Product</h2>
                <div className="card-body">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="itemName">
                                    <Form.Label>Item Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter item name" value={itemName} onChange={(e) => setItemName(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="category">
                                    <Form.Label>Category</Form.Label>
                                    <Form.Control as="select" value={category} onChange={(e) => {
                                        setCategory(e.target.value);
                                        fetchSubCategories(e.target.value);
                                    }}>
                                        <option value="">Select Category</option>
                                        {categories.map(cat => (
                                            <option key={cat.id} value={cat.id}>{cat.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="subCategory">
                                    <Form.Label>Subcategory</Form.Label>
                                    <Form.Control as="select" value={subCategory} onChange={(e) => setSubCategory(e.target.value)}>
                                        <option value="">Select Subcategory</option>
                                        {subCategories.map(subCat => (
                                            <option key={subCat.id} value={subCat.id}>{subCat.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="dimensions">
                                    <Form.Label>Dimensions</Form.Label>
                                    <Form.Control type="text" placeholder="Enter dimensions" value={dimensions} onChange={(e) => setDimensions(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="price">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control type="number" placeholder="Enter price" value={price} onChange={(e) => setPrice(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="discount">
                                    <Form.Label>Discount</Form.Label>
                                    <Form.Control type="number" placeholder="Enter discount" value={discount} onChange={(e) => setDiscount(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="stockQty">
                                    <Form.Label>Stock Quantity</Form.Label>
                                    <Form.Control type="number" placeholder="Enter stock quantity" value={stockQty} onChange={(e) => setStockQty(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="isFeatured">
                                    <Form.Label>Is Featured</Form.Label>
                                    <Form.Check type="checkbox" label="Featured" checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)} />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="isReturnable">
                                    <Form.Label>Is Returnable</Form.Label>
                                    <Form.Check type="checkbox" label="Featured" checked={Isreturnable} onChange={(e) => setIsReturnable(e.target.checked)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3 " controlId="image">
                                    <Form.Label>Upload Main Image</Form.Label>
                                    <Form.Control type="file" onChange={handleImageUpload} />
                                    {image && <Card className="mt-3 productmain-images" >
                                        <Card.Img variant="top" src={URL.createObjectURL(image)} />
                                        <Card.Body>
                                            <Card.Title>{imageName}</Card.Title>
                                        </Card.Body>
                                    </Card>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="sellingPrice">
                                    <Form.Label>Selling Price</Form.Label>
                                    <Form.Control type="number" placeholder="Enter selling price" value={sellingPrice} onChange={(e) => setSellingPrice(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="tax">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Control type="number" placeholder="Enter tax" value={tax} onChange={(e) => setTax(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="packageWeight">
                                    <Form.Label>Package Weight</Form.Label>
                                    <Form.Control type="number" placeholder="Enter package weight" value={packageWeight} onChange={(e) => setPackageWeight(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="packageHeight">
                                    <Form.Label>Package Height</Form.Label>
                                    <Form.Control type="number" placeholder="Enter package height" value={packageHeight} onChange={(e) => setPackageHeight(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="packageLength">
                                    <Form.Label>Package Length</Form.Label>
                                    <Form.Control type="number" placeholder="Enter package length" value={packageLength} onChange={(e) => setPackageLength(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="packageWidth">
                                    <Form.Label>Package Width</Form.Label>
                                    <Form.Control type="number" placeholder="Enter package width" value={packageWidth} onChange={(e) => setPackageWidth(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="fabricType">
                                    <Form.Label>Fabric Type</Form.Label>
                                    <Form.Control as="select" value={fabricTypeID} onChange={(e) => setFabricTypeID(e.target.value)}>
                                        <option value="">Select Fabric Type</option>
                                        {fabricTypes.map(fabricType => (
                                            <option key={fabricType.id} value={fabricType.id}>{fabricType.fabricname}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="manufacturedBy">
                                    <Form.Label>Manufactured By</Form.Label>
                                    <Form.Control type="text" placeholder="Enter manufacturer" value={manufacturedBy} onChange={(e) => setManufacturedBy(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="washCare">
                                    <Form.Label>Wash Care</Form.Label>
                                    <Form.Control type="text" placeholder="Enter wash care instructions" value={washCare} onChange={(e) => setWashCare(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="fit">
                                    <Form.Label>Fit</Form.Label>
                                    <Form.Control as="select" value={fitID} onChange={(e) => setFitID(e.target.value)}>
                                        <option value="">Select Fit</option>
                                        {fits.map(fit => (
                                            <option key={fit.id} value={fit.id}>{fit.fitname}</option>
                                        ))}
                                    </Form.Control>

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="gender">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Control type="text" placeholder="Enter gender" value={gender} onChange={(e) => setGender(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="colorID">
                                    <Form.Label>Color</Form.Label>
                                    <Form.Control as="select" value={colorID} onChange={(e) => setColorID(e.target.value)}>
                                        <option value="">Select Color</option>
                                        {colors.map(color => (
                                            <option key={color.id} value={color.id}>{color.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="skqnumber">
                                    <Form.Label>SKU Number</Form.Label>
                                    <Form.Control type="text" placeholder="Enter SKU number" value={skqnumber} onChange={(e) => setSkqnumber(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="isAvailable">
                                    <Form.Label>Is Available</Form.Label>
                                    <Form.Check type="checkbox" label="Available" checked={isAvailable} onChange={(e) => setIsAvailable(e.target.checked)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group controlId="formStock">
                                        <Form.Label>Stock Quantity by Size</Form.Label>
                                        <Row>
                                            {Object.keys(stock).map(size => (
                                                <Col md={2} key={size}>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder={size.toUpperCase()}
                                                        value={stock[size]}
                                                        onChange={(e) => handleStockChange(e, size)}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Row>
                        <Row>
                            {sampleImages.map((sampleImage, index) => (
                                <Col md={3} key={index}>
                                    <Form.Group className="mb-3" controlId={`sampleImage${index + 1}`}>
                                        <Form.Label>Upload Sample Image {index + 1}</Form.Label>
                                        <Form.Control type="file" onChange={(e) => handleSampleImageUpload(e, index)} />
                                        {sampleImage && (
                                            <Card className="mt-3">
                                                <Card.Img variant="top" src={URL.createObjectURL(sampleImage)} />
                                                <Card.Body>
                                                    <Card.Title>Sample Image {index + 1}</Card.Title>
                                                </Card.Body>
                                            </Card>
                                        )}
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3" controlId="videoUrl">
                                    <Form.Label>Video URL</Form.Label>
                                    <Form.Control type="text" placeholder="Enter video URL" value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="videoFile">
                                    <Form.Label>Or Upload Video File</Form.Label>
                                    <Form.Control type="file" onChange={handleVideoUpload} />
                                    {videoFile && (
                                        <Card className="mt-3">
                                            <Card.Body>
                                                <Card.Title>{videoFile.name}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button variant="primary" type="submit">Add Product</Button>
                    </Form>
                </div>
                <CSVUpload ref={csvUploadRef} onUploadSuccess={() => console.log('Upload successful!')} />
                {/* resetForm(); */}
            </div>
            <ToastContainer />

        </div>
    );
}

export default AddProduct;


