// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Button } from 'react-bootstrap';
// import { FaEdit, FaTrash } from 'react-icons/fa';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function Colormanager() {
//     const [colorId, setColorId] = useState(null);
//     const [colorName, setColorName] = useState("");
//     const [colorCode, setColorCode] = useState("");
//     const [imageFile, setImageFile] = useState("");
//     const [imagePreview, setImagePreview] = useState("");
//     const [showModal, setShowModal] = useState(false);
//     const [colors, setColors] = useState([]);
//     const token = localStorage.getItem("responsetoken");
//     const authorid = localStorage.getItem("authorid");

//     useEffect(() => {
//         fetchColors();
//     }, []);
// debugger;
//     const fetchColors = async () => {
//         try {
//             const url = `https://api.rollerfashions.com/api/Color/List_Color`;
//             const response = await axios.get(url,{
//                 headers:{
//                     "Authorization":`Bearer ${token}`
//                 }
//             });
//             setColors(response.data);
//         } catch (error) {
//             console.error("Error fetching colors:", error);
//             toast.error("An error occurred while fetching colors. Please try again.", {
//                 position: "top-right",
//                 autoClose: 3000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//             });
//         }
//     };

//     const handleColorNameChange = (e) => {
//         setColorName(e.target.value);
//     };

//     const handleColorCodeChange = (e) => {
//         setColorCode(e.target.value);
//     };


//     const handleImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             const reader = new FileReader();
//             reader.onloadend = () => {
//                 const base64String = reader.result;
//                 console.log("Base64 String:", base64String); // Log the base64 string
//                 setImagePreview(base64String);
//                 setImageFile(file);
//             };
//             reader.readAsDataURL(file);
//         }
//     };


//     const convertImageToBase64 = (file) => {
//         return new Promise((resolve, reject) => {
//             const reader = new FileReader();
//             reader.onloadend = () => {
//                 resolve(reader.result);
//             };
//             reader.onerror = reject;
//             reader.readAsDataURL(file);
//         });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const base64Image = await convertImageToBase64(imageFile);
//             const obj = {
//                 name: colorName,
//                 code: colorCode,
//                 file: base64Image,
//                 author_ID:authorid,
//             };

//             const url = colorId
//                 ? `https://api.rollerfashions.com/api/Color/Edit_color`
//                 : `https://api.rollerfashions.com/api/Color/Add_Color`;

//             if (colorId) obj.id = colorId;

//             const response = await axios.post(url, obj, {
//                 headers: {
//                     'Authorization':`Bearer ${token}`,
//                     'Content-Type': 'application/json',
//                 }
//             });

//             toast.success(response.data.responsemessage, {
//                 position: "top-right",
//                 autoClose: 3000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//             });

//             setShowModal(false);
//             fetchColors();
//         } catch (error) {
//             console.error("Error adding/editing color:", error);
//             toast.error("An error occurred. Please try again.", {
//                 position: "top-right",
//                 autoClose: 3000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//             });
//         }
//     };

//     const handleEdit = (color) => {
//         setColorId(color.id);
//         setColorName(color.name);
//         setColorCode(color.code);
//         setImagePreview(color.image);
//         setShowModal(true);
//     };

//     const handleDelete = async (id) => {
//         try {
//             const url = `https://api.rollerfashions.com/api/Color/Delete_Color`;
//             const obj = {
//                 id: id,
//                 author_ID:authorid,
//             };

//             const response = await axios.post(url, obj, {
//                 headers: {
//                     'Authorization':`Bearer ${token}`,
//                     'Content-Type': 'application/json'
//                 }
//             });

//             toast.success(response.data.responsemessage, {
//                 position: "top-right",
//                 autoClose: 3000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//             });

//             fetchColors();
//         } catch (error) {
//             console.error("Error deleting color:", error);
//             toast.error("An error occurred while deleting the color. Please try again.", {
//                 position: "top-right",
//                 autoClose: 3000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//             });
//         }
//     };

//     return (
//         <div className="content-wrapper mt-5">
//             <button
//                 type="button"
//                 className="btn btn-primary mt-5"
//                 onClick={() => {
//                     setColorId(null);
//                     setColorName("");
//                     setColorCode("");
//                     setImageFile("");
//                     setImagePreview("");
//                     setShowModal(true);
//                 }}
//             >
//                 Add Color
//             </button>

//             {showModal && (
//                 <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
//                     <div className="modal-dialog modal-dialog-centered" role="document">
//                         <div className="modal-content">
//                             <div className="modal-header">
//                                 <h5 className="modal-title">{colorId ? "Edit Color" : "Add Color"}</h5>
//                                 <button type="button" className="close" onClick={() => setShowModal(false)}>
//                                     <span>&times;</span>
//                                 </button>
//                             </div>
//                             <div className="modal-body">
//                                 <form className="pt-0" onSubmit={handleSubmit}>
//                                     <div className="form-floating form-floating-outline ">
//                                         <input
//                                             type="text"
//                                             className="form-control"
//                                             id="color_name"
//                                             placeholder="Enter Color Name"
//                                             value={colorName}
//                                             onChange={handleColorNameChange}
//                                         />
//                                         <label htmlFor="color_name ">Color Name</label>
//                                     </div>
//                                     <div className="form-floating form-floating-outline mb-4">
//                                         <input
//                                             type="text"
//                                             id="color_code"
//                                             className="form-control"
//                                             placeholder="Enter Color Code"
//                                             value={colorCode}
//                                             onChange={handleColorCodeChange}
//                                         />
//                                         <label htmlFor="color_code">Color Code</label>
//                                     </div>
//                                     <div className="form-floating form-floating-outline mb-4">
//                                         <input
//                                             type="file"
//                                             id="image_upload"
//                                             className="form-control"
//                                             accept="image/*"
//                                             onChange={handleImageChange}
//                                         />
//                                         {/* <label htmlFor="image_upload">Upload Image</label> */}
//                                     </div>
//                                     {imagePreview && (
//                                         <img src={imagePreview} alt="Preview" style={{ width: "100px", height: "100px", objectFit: "cover" }} />
//                                     )}
//                                     <div className="mb-3">
//                                         <Button variant='primary' className="btn btn-secondary" onClick={handleSubmit} >{colorId ? "Edit" : "Add"}</Button>
//                                         <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}

//             <div className="app-ecommerce-category over">
//                 <div className="card">
//                     <div className="card-datatable table-responsive">
//                         <table className="table">
//                             <thead>
//                                 <tr>
//                                     <th>Color Name</th>
//                                     <th>Color Code</th>
//                                     <th>Image</th>
//                                     <th>Actions</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {colors.map((color) => (
//                                     <tr key={color.id}>
//                                         <td>{color.name}</td>
//                                         <td>{color.code}</td>
//                                         <td>
//                                             {color.image && (
//                                                 <img src={`data:image/jpeg;base64,${color.image}`} alt={color.name} style={{ width: "50px", height: "50px", }} />
//                                             )}
//                                         </td>
//                                         <td>
//                                             <Button className="btn btn-primary me-2" onClick={() => handleEdit(color)}> <FaEdit /></Button>
//                                             <Button className="btn btn-danger" onClick={() => handleDelete(color.id)}> <FaTrash /></Button>
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//             </div>
//             <ToastContainer />
//         </div>
//     );
// }

// export default Colormanager;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Colormanager() {
    const [colorId, setColorId] = useState(null);
    const [colorName, setColorName] = useState("");
    const [colorCode, setColorCode] = useState("");
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [colors, setColors] = useState([]);
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchColors();
    }, []);

    const fetchColors = async () => {
        try {
            const url = `https://api.rollerfashions.com/api/Color/List_Color`;
            const response = await axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            setColors(response.data);
        } catch (error) {
            console.error("Error fetching colors:", error);
            toast.error("An error occurred while fetching colors. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleColorNameChange = (e) => {
        setColorName(e.target.value);
    };

    const handleColorCodeChange = (e) => {
        setColorCode(e.target.value);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            setImageFile(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('name', colorName);
            formData.append('code', colorCode);
            formData.append('author_ID', authorid);
            if (imageFile) {
                formData.append('file', imageFile);
            }
            if (colorId) {
                formData.append('id', colorId);
            }

            const url = colorId
                ? `https://api.rollerfashions.com/api/Color/Edit_color`
                : `https://api.rollerfashions.com/api/Color/Add_Color`;

            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                }
            });

            toast.success(response.data.responsemessage, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            setShowModal(false);
            fetchColors();
        } catch (error) {
            console.error("Error adding/editing color:", error);
            toast.error("An error occurred. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleEdit = (color) => {
        setColorId(color.id);
        setColorName(color.name);
        setColorCode(color.code);
        setImagePreview(color.file ? `${color.file}` : "");
        setShowModal(true);
    };

    const inputStyle = {
        color: 'white',
      };
    const handleDelete = async (id) => {
        try {
            const url = `https://api.rollerfashions.com/api/Color/Delete_Color`;
            const obj = {
                id: id,
                author_ID: authorid,
            };

            const response = await axios.post(url, obj, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            toast.success(response.data.responsemessage, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            fetchColors();
        } catch (error) {
            console.error("Error deleting color:", error);
            toast.error("An error occurred while deleting the color. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    return (
        <div className="content-wrapper mt-5">
            <button
                type="button"
                className="btn btn-primary mt-5"
                onClick={() => {
                    setColorId(null);
                    setColorName("");
                    setColorCode("");
                    setImageFile(null);
                    setImagePreview("");
                    setShowModal(true);
                }}
            >
                Add Color
            </button>

            {showModal && (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{colorId ? "Edit Color" : "Add Color"}</h5>
                                <button type="button" className="close" onClick={() => setShowModal(false)}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form className="pt-0" onSubmit={handleSubmit}>
                                    <div className="form-floating form-floating-outline ">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="color_name"
                                            placeholder="Enter Color Name"
                                            value={colorName}
                                            style={inputStyle}
                                            onChange={handleColorNameChange}
                                        />
                                        <label htmlFor="color_name ">Color Name</label>
                                    </div>
                                    <div className="form-floating form-floating-outline mb-4">
                                        <input
                                            type="text"
                                            id="color_code"
                                            className="form-control"
                                            placeholder="Enter Color Code"
                                            value={colorCode}
                                            onChange={handleColorCodeChange}
                                        />
                                        <label htmlFor="color_code">Color Code</label>
                                    </div>
                                    <div className="form-floating form-floating-outline mb-4">
                                        <input
                                            type="file"
                                            id="image_upload"
                                            className="form-control"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            style={inputStyle}
                                        />
                                    </div>
                                    {imagePreview && (
                                        <img src={imagePreview} alt="Preview" style={{ width: "100px", height: "100px", objectFit: "cover" }} />
                                    )}
                                    <div className="mb-3">
                                        <Button variant='primary' className="btn btn-secondary" onClick={handleSubmit} >{colorId ? "Edit" : "Add"}</Button>
                                        <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="app-ecommerce-category over">
                <div className="card">
                    <div className="card-datatable table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Color Name</th>
                                    <th>Color Code</th>
                                    <th>Image</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {colors.map((color) => (
                                    <tr key={color.id}>
                                        <td>{color.name}</td>
                                        <td>{color.code}</td>
                                        <td>
                                            {color.image && (
                                                <img src={`${color.image}`} alt={color.name} style={{ width: "50px", height: "50px" }} />
                                            )}
                                        </td>
                                        <td>
                                            <Button className="btn btn-primary me-2" onClick={() => handleEdit(color)}> <FaEdit /></Button>
                                            <Button className="btn btn-danger" onClick={() => handleDelete(color.id)}> <FaTrash /></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Colormanager;
