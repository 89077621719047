


import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Form, Container, Row, Col, Table } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import "../assets/css/subcategory.css";
import Leftmenu from "./LeftMenu";

function Subcategory() {
    const { categoryId } = useParams();
    const [subcategoryName, setSubcategoryName] = useState("");
    const [subcategoryDiscount, setSubcategoryDiscount] = useState("");
    const [isFeatured, setIsFeatured] = useState(false);
    const [imageFile, setImageFile] = useState(null); // For base64 encoded image
    const [imagePreview, setImagePreview] = useState(null); // For image preview
    const [showModal, setShowModal] = useState(false);
    const [editingSubcategory, setEditingSubcategory] = useState(null);
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");
    const [subcategories, setSubcategories] = useState([]);
    const [id, setSubcategoriesId] = useState("")

    useEffect(() => {
        fetchSubcategories();
    }, [categoryId]);

    const fetchSubcategories = async () => {
        try {
            const url = `https://api.rollerfashions.com/api/Subcategory/List_subcategory?Category_ID=${categoryId}`;
            const response = await axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-type": "application/json",
                }
            });
            setSubcategories(response.data);
        } catch (error) {
            console.error("Error fetching subcategories:", error);
            toast.error("An error occurred while fetching subcategories. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleSubcategoryNameChange = (e) => {
        setSubcategoryName(e.target.value);
    };

    const handleSubcategoryDiscountChange = (e) => {
        setSubcategoryDiscount(e.target.value);
    };

    const handleFeaturedChange = (e) => {
        setIsFeatured(e.target.checked);
    };
























    const inputStyle = {
        color: 'white',
      };
    // const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImagePreview(reader.result);
    //             setImageFile(reader.result); // Set the image file as base64
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImagePreview(URL.createObjectURL(file)); // Preview the image without converting it to base64
            setImageFile(file); // Set the image file as it is
        }
    };
    debugger;
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     debugger;
    //     try {
    //         let url;
    //         let method;
    //         let data = {
    //             name: subcategoryName,
    //             image: imageFile, // Base64 data without the "data:image/jpeg;base64," prefix
    //             isfeature: isFeatured,
    //             discount: parseFloat(subcategoryDiscount),
    //             category_ID: categoryId,
    //             author_ID: authorid,

    //         };

    //         if (id) {
    //             url = `https://api.rollerfashions.com/api/Subcategory/Edit_subcategory`;
    //             method = 'post';
    //             data.id= id;
    //             // data.id: editingSubcategory.id; // Include id for editing
    //         } else {
    //             url = `https://api.rollerfashions.com/api/Subcategory/Create_subcategory`;
    //             method = 'post';
    //         }

    //         const response = await axios[method](url, data, {
    //             headers: {
    //                 "Authorization": `Bearer ${token}`,
    //                 "Content-Type": "application/json"
    //             },
    //         });

    //         toast.success(`Subcategory ${id ? 'updated' : 'created'} successfully`, {
    //             position: "top-right",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //         });

    //         setShowModal(false);
    //         setEditingSubcategory(null);
    //         setSubcategoryName('');
    //         setSubcategoryDiscount('');
    //         setIsFeatured(false);
    //         setImageFile(null); // Reset image state
    //         setImagePreview(null);
    //         setSubcategoriesId('') // Reset image preview

    //         fetchSubcategories(); // Refresh subcategories list after update

    //     } catch (error) {
    //         console.error(`Error ${id ? 'updating' : 'creating'} subcategory:`, error);
    //         toast.error("An error occurred. Please try again.", {
    //             position: "top-right",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //         });
    //     }
    // };
    const handleEdit = (subcategory) => {
        setEditingSubcategory(subcategory);
        setSubcategoryName(subcategory.name);
        setSubcategoryDiscount(subcategory.discount);
        setIsFeatured(subcategory.isfeature);
        setSubcategoriesId(subcategory.id);
    
        // If the image is already stored as a URL or base64 string
        setImagePreview(subcategory.image);
        setImageFile(null); // Reset to prevent overwriting unless a new file is chosen
        setShowModal(true);
    };
    debugger;
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let url;
            let method;
        
            // Create a FormData object
            const formData = new FormData();
        
            // Append form fields to FormData
            formData.append('name', subcategoryName);
            formData.append('discount', parseFloat(subcategoryDiscount));
            formData.append('isfeature', isFeatured);
            formData.append('category_ID', categoryId);
            formData.append('author_ID', authorid);
        
            // If editing, add the subcategory ID
            if (id) {
                formData.append('id', id); // Append the id for editing
                url = `https://api.rollerfashions.com/api/Subcategory/Edit_subategory`;
                method = 'post';
            } else {
                url = `https://api.rollerfashions.com/api/Subcategory/Create_subcategory`;
                method = 'post';
            }
        
            // Only append the image file if a new one is selected
            if (imageFile) {
                formData.append('file', imageFile); // Append the new image file
            }
        
            // Send the FormData with Axios
            const response = await axios[method](url, formData, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "multipart/form-data", // Set the correct content type for form data
                },
            });
        
            toast.success(`Subcategory ${id ? 'updated' : 'created'} successfully`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        
            // Reset the form fields
            setShowModal(false);
            setEditingSubcategory(null);
            setSubcategoryName('');
            setSubcategoryDiscount('');
            setIsFeatured(false);
            setImageFile(null);
            setImagePreview(null);
            setSubcategoriesId('');
        
            fetchSubcategories(); // Refresh subcategories list after update
        
        } catch (error) {
            console.error(`Error ${id ? 'updating' : 'creating'} subcategory:`, error);
            toast.error("An error occurred. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };
    
    console.log("Editing Subcategory ID:", id);


    const handleDelete = async (subcategoryId) => {
        try {
            const url = `https://api.rollerfashions.com/api/Subcategory/Delete_subcategory`;
            await axios.post(url, { id: subcategoryId, author_ID: authorid }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            toast.success("Subcategory deleted successfully", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            fetchSubcategories();
        } catch (error) {
            console.error("Error deleting subcategory:", error);
            toast.error("An error occurred. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    return (
        <Container fluid>
            <div className="subcategory-container">
                <Row>
                    {/* Left Menu Component */}
                    <Col md={2}>
                        <Leftmenu />
                    </Col>

                    {/* Main Content */}
                    <Col md={10} className="container-fluid content-wrapper">
                        <div className="">
                            <h2 className='coupon-title'>Category Management / Sub category</h2>
                            <Button className="add-button" onClick={() => setShowModal(true)}>Add Subcategory</Button>



                            {/* Modal for Adding/Edit Subcategory */}
                            {showModal && (
                                <Modal className="custom-modal" show={showModal} onHide={() => { setShowModal(false); setEditingSubcategory(null); }}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{editingSubcategory ? "Edit Subcategory" : "Add Subcategory"}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group controlId="subcategoryName">
                                                <Form.Label>Subcategory Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={subcategoryName}
                                                    onChange={handleSubcategoryNameChange}
                                                    style={inputStyle}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="subcategoryDiscount">
                                                <Form.Label>Discount</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={subcategoryDiscount}
                                                    onChange={handleSubcategoryDiscountChange}
                                                    style={inputStyle}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="imageFile">
                                                <Form.Label>Image</Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    onChange={handleImageChange}
                                                />
                                                {imagePreview && (
                                                    <img
                                                        src={imagePreview}
                                                        alt="Preview"
                                                        className="preview-image"
                                                    />
                                                )}
                                            </Form.Group>
                                            <Form.Group controlId="isFeatured">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Featured"
                                                    checked={isFeatured}
                                                    onChange={handleFeaturedChange}
                                                />
                                            </Form.Group>
                                            <Button type="submit" className="submit-button">{editingSubcategory ? "Update" : "Add"} Subcategory</Button>
                                        </Form>
                                    </Modal.Body>
                                </Modal>
                            )}

                            {/* Subcategory Table */}
                            <div className="subcategory-table">
                                <div className="table-wrapper">
                                    <Table className="table-dark">
                                        <thead>
                                            <tr>
                                                <th>Subcategory Name</th>
                                                <th>Discount</th>
                                                <th>Featured</th>
                                                <th>Image</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subcategories.length > 0 ? (
                                                subcategories.map((subcategory) => (
                                                    <tr key={subcategory.id}>
                                                        <td>{subcategory.name}</td>
                                                        <td>{subcategory.discount}</td>
                                                        <td>{subcategory.isfeature ? 'Yes' : 'No'}</td>
                                                        <td>
                                                            {subcategory.image ? (
                                                                <img src={`${subcategory.image}`} alt={subcategory.name} className="table-image" />
                                                            ) : (
                                                                "No Image"
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Button variant="info" onClick={() => handleEdit(subcategory)} className="mr-2">
                                                                <FaEdit />
                                                            </Button>
                                                            <Button variant="danger" onClick={() => handleDelete(subcategory.id)}>
                                                                <FaTrash />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="5">No subcategories found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                        </div>

                    </Col>
                </Row>
            </div>
            <ToastContainer />
        </Container>
    );
}

export default Subcategory;

