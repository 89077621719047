import React, { useState, useEffect } from 'react';
import { Table, Button, Card, CardBody, Modal } from 'react-bootstrap';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import "../assets/css/productlist.css";

function ChildProductList() {
    const [childProducts, setChildProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchChildProducts();
    }, []);
debugger;
    const fetchChildProducts = async () => {
        try {
            const response = await axios.get("https://api.rollerfashions.com/api/Product/List_duplicateProduct", {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'content-type': 'application/json',
                },
            });
            setChildProducts(response.data);
        } catch (error) {
            console.error("Error fetching child products:", error);
            toast.error("An error occurred while fetching child products. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleDelete = async (productId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const url = `https://api.rollerfashions.com/api/Product/Delete_Product`;
                    const obj = {
                        id: productId,
                        author_ID: authorid
                    };
debugger;
                    const response = await axios.post(url, obj, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }
                    });

                    toast.success(response.data.responsemessage, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    fetchChildProducts();
                    Swal.fire(
                        'Deleted!',
                        'The duplicate product has been deleted.',
                        'success'
                    );
                } catch (error) {
                    console.error("Error deleting child product:", error);
                    toast.error("An error occurred while deleting the duplicate product. Please try again.", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        });
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage('');
    };

    return (
        <div className="container-fluid content-wrapper">
            <Card>
                <CardBody>
                    <h2 className='coupon-title'>Product Management / Duplicate Product List</h2>
                    <Table className='table table-dark'>
                        <thead>
                            <tr>
                                <th>Display Image</th>
                                <th>Name</th>
                                <th>SKU Number</th>
                                <th>Price</th>
                                <th>Selling Price</th>
                                <th>Category</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {childProducts && childProducts.map(product => (
                                <tr key={product.id}>
                                    <td>
                                        <img
                                            src={`${product.display_image}`}
                                            alt={product.name}
                                            className="product-list-image"
                                            onClick={() => handleImageClick(product.display_image)}
                                        />
                                    </td>
                                    <td className="product-name">{product.name}</td>
                                    <td>{product.skq_number}</td>
                                    <td>{product.price}</td>
                                    <td>{product.selling_price}</td>
                                    <td>{product.category}</td>
                                    <td>
                                        <Link to={`/editchildproduct/${product.id}`}>
                                            <FaEdit />
                                        </Link>
                                        <Button variant="danger" className="action-button" onClick={() => handleDelete(product.id)}>
                                            <FaTrash />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Product Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={`${selectedImage}`} alt="Product" className="img-fluid" />
                </Modal.Body>
            </Modal>

            <ToastContainer />
        </div>
    );
}

export default ChildProductList;
