import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container, Card, } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Sidenav from './LeftMenu';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/editproduct.css';

function EditProduct() {
    const [product, setProduct] = useState({});
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [colors, setColors] = useState([]);
    const [image, setImage] = useState(null); // State to store uploaded image file
    const [imageName, setImageName] = useState('');// State to store image file name
    const [stock, setStock] = useState({
        xs: "",
        s: "",
        l: "",
        xl: "",
        xxl: "",

    });

    const { id } = useParams(); // Use useParams to get route parameters
    const [videoPreview, setVideoPreview] = useState('');
    const authorid = localStorage.getItem("authorid")
    debugger;
    // useEffect(() => {
    //     const fetchProduct = async () => {
    //         try {
    //             const token = localStorage.getItem("responsetoken");
    //             const url = `https://api.rollerfashions.com/api/Product/Get_Product?Product_ID=${id}`;
    //             const response = await axios.get(url, {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${token}`,
    //                 }
    //             });
    //             if (response.data) {
    //                 setProduct(response.data);
    //                 fetchCategories();
    //                 fetchSubCategories(response.data.category_ID);
    //                 fetchColors();
    //             } else {
    //                 console.error("Product details not found.");
    //             }
    //         } catch (error) {
    //             console.error("Error fetching product details:", error);
    //         }
    //     };

    //     fetchProduct();
    // }, [id]);
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const token = localStorage.getItem("responsetoken");
                const url = `https://api.rollerfashions.com/api/Product/Get_Product?Product_ID=${id}`;
                const response = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });
                if (response.data) {
                    setProduct(response.data);
                    setStock({
                        xs: response.data.xs || '',
                        s: response.data.s || '',
                        m: response.data.m || '',
                        l: response.data.l || '',
                        xl: response.data.xl || '',
                        xxl: response.data.xxl || '',
                    });
                    fetchCategories();
                    fetchSubCategories(response.data.category_ID);
                    fetchColors();
                } else {
                    console.error("Product details not found.");
                }
            } catch (error) {
                console.error("Error fetching product details:", error);
            }
        };

        fetchProduct();
    }, [id]);
    debugger;
    const fetchCategories = async () => {
        try {
            const token = localStorage.getItem("responsetoken");
            const url = `https://api.rollerfashions.com/api/Category/List_Category`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const fetchSubCategories = async (categoryID) => {
        try {
            const token = localStorage.getItem("responsetoken");
            const url = `https://api.rollerfashions.com/api/SubCategory/List_SubCategory?Category_ID=${categoryID}`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            setSubCategories(response.data);
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };

    const fetchColors = async () => {
        try {
            const token = localStorage.getItem("responsetoken");
            const url = `https://api.rollerfashions.com/api/Color/List_Color`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            setColors(response.data);
        } catch (error) {
            console.error("Error fetching colors:", error);
        }
    };
    const inputStyle = {
        color: 'white',
      };
    debugger;
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('id', id || '');
        formData.append('name', product.name || '');
        formData.append('category_ID', product.category_ID || '');
        formData.append('subcategory_ID', product.subcategory_ID || '');
        formData.append('dimensions', product.dimensions || '');
        formData.append('price', product.price || '');
        formData.append('selling_price', product.selling_price || '');
        formData.append('description', product.description || '');
        formData.append('isfeature', product.isfeature ? 'true' : 'false');
        formData.append('isavailable', product.isavailable ? 'true' : 'false');
        formData.append('Isreturnable', product.Isreturnable ? 'true' : 'false');
        formData.append('color_ID', product.color_ID || '');
        formData.append('skq_number', product.skq_number || '');
        formData.append('s', stock.s || '');
        formData.append('m', stock.m || '');
        formData.append('l', stock.l || '');
        formData.append('xl', stock.xl || '');
        formData.append('xxl', stock.xxl || '');
        if (product.sample_image1) formData.append('sample_image1', product.sample_image1);
        if (product.sample_image2) formData.append('sample_image2', product.sample_image2);
        if (product.sample_image3) formData.append('sample_image3', product.sample_image3);
        if (product.sample_image4) formData.append('sample_image4', product.sample_image4);

        if (image) {
            formData.append('image', image);
        }

        if (product.video) {
            formData.append('video', product.video);
        }

        try {
            const token = localStorage.getItem("responsetoken");
            const authorid = localStorage.getItem("authorid");
            formData.append('author_ID', authorid);

            const response = await axios.post('https://api.rollerfashions.com/api/Product/Edit_Product', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });

            toast.success('Product updated successfully');
        } catch (error) {
            console.error("Error updating product:", error.response ? error.response.data : error.message);
            toast.error('Failed to update product');
        }
    };


    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size <= 102400) {
                setImage(file);
                setImageName(file.name);
            } else {
                alert("Image size should be less than or equal to 100KB");
            }
        }
    };
    const handleSampleImageUpload = (e, imageKey) => {
        const file = e.target.files[0];
        if (file) {
            setProduct((prevProduct) => ({
                ...prevProduct,
                [imageKey]: file,
            }));
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setProduct({ ...product, [name]: value });
    // };

    const handleStockChange = (e) => {
        const { name, value } = e.target;
        setStock((prevStock) => ({
            ...prevStock,
            [name]: value ? parseInt(value) : 0, // Convert value to an integer or default to 0
        }));
    };


    const handleVideoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const videoURL = URL.createObjectURL(file);
            setVideoPreview(videoURL);
            setProduct({ ...product, video: file });
        }
    };

    if (!product || Object.keys(product).length === 0) {
        return <div>Loading......</div>;
    }

    debugger;
    return (
        <Container fluid>
            <Row>
                <Col md={2}>
                    <Sidenav />
                </Col>
                <Col md={10}>
                    <div className="container-fluid editproduct editproduct-container">
                        <h2>Edit Product</h2>
                        <div className="card editproduct-card">
                            <div className="card-body">
                                <Form onSubmit={handleSubmit}>
                               
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="itemName">
                                                <Form.Label>Item Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter item name"
                                                    value={product.name || ''}
                                                    onChange={(e) => setProduct({ ...product, name: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="category">
                                                <Form.Label>Category</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={product.category_ID || ''}
                                                    onChange={(e) => {
                                                        setProduct({ ...product, category_ID: e.target.value });
                                                        fetchSubCategories(e.target.value);
                                                    }}
                                                >
                                                    <option value="">Select category</option>
                                                    {categories.map((category) => (
                                                        <option key={category.id} value={category.id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="subcategory">
                                                <Form.Label>Subcategory</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={product.subcategory_ID || ''}
                                                    onChange={(e) => setProduct({ ...product, subcategory_ID: e.target.value })}
                                                >
                                                    <option value="">Select subcategory</option>
                                                    {subCategories.map((subcategory) => (
                                                        <option key={subcategory.id} value={subcategory.id}>
                                                            {subcategory.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="color">
                                                <Form.Label>Color</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={product.color_ID || ''}
                                                    onChange={(e) => setProduct({ ...product, color_ID: e.target.value })}
                                                >
                                                    <option value="">Select color</option>
                                                    {colors.map((color) => (
                                                        <option key={color.id} value={color.id}>
                                                            {color.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="price">
                                                <Form.Label>Price</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter price"
                                                    value={product.price || ''}
                                                    onChange={(e) => setProduct({ ...product, price: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="sellingPrice">
                                                <Form.Label>Selling Price</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter selling price"
                                                    value={product.selling_price || ''}
                                                    onChange={(e) => setProduct({ ...product, selling_price: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="stock">
                                                <Form.Label>Stock</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter stock quantity"
                                                    value={product.stock || ''}
                                                    onChange={(e) => setProduct({ ...product, stock: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group controlId="isFeature">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Is Featured"
                                                    checked={product.isfeature}
                                                    onChange={(e) => setProduct({ ...product, isfeature: e.target.checked })}
                                                    
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group controlId="isAvailable">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Is Available"
                                                    checked={product.isavailable}
                                                    onChange={(e) => setProduct({ ...product, isavailable: e.target.checked })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group controlId="Isreturnable">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Is Returnable"
                                                    checked={product.Isreturnable}
                                                    onChange={(e) => setProduct({ ...product, Isreturnable: e.target.checked })}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group controlId="description">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    placeholder="Enter description"
                                                    value={product.description || ''}
                                                    onChange={(e) => setProduct({ ...product, description: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="dimensions">
                                                <Form.Label>Dimensions</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter dimensions"
                                                    value={product.dimensions || ''}
                                                    onChange={(e) => setProduct({ ...product, dimensions: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="xs">
                                                    <Form.Label>XS</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter quantity for XS"
                                                        value={stock.xs || ''}
                                                        onChange={handleStockChange}
                                                        name="xs"
                                                        style={inputStyle} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="s">
                                                    <Form.Label>S</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter quantity for S"
                                                        value={stock.s || ''}
                                                        onChange={handleStockChange}
                                                        name="s"
                                                        style={inputStyle} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="m">
                                                    <Form.Label>M</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter quantity for M"
                                                        value={stock.m || ''}
                                                        onChange={handleStockChange}
                                                        name="m"
                                                        style={inputStyle} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="l">
                                                    <Form.Label>L</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter quantity for L"
                                                        value={stock.l || ''}
                                                        onChange={handleStockChange}
                                                        name="l"
                                                        style={inputStyle} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="xl">
                                                    <Form.Label>XL</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter quantity for XL"
                                                        value={stock.xl || ''}
                                                        onChange={handleStockChange}
                                                        name="xl"
                                                        style={inputStyle} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="xxl">
                                                    <Form.Label>XXL</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter quantity for XXL"
                                                        value={stock.xxl || ''}
                                                        onChange={handleStockChange}
                                                        name="xxl"
                                                        style={inputStyle} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="discount">
                                                <Form.Label>Discount</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter discount"
                                                    value={product.discount || ''}
                                                    onChange={(e) => setProduct({ ...product, discount: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="tax">
                                                <Form.Label>Tax</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter tax"
                                                    value={product.tax || ''}
                                                    onChange={(e) => setProduct({ ...product, tax: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>

                                        </Col>
                                        <Col>

                                        </Col>
                                    </Row>

                                    <Form.Group controlId="displayImage">
                                        <Form.Label>Display Image</Form.Label>
                                        <div>
                                            <img src={`${product.display_image}`} alt={product.name} className="product-list-images" />
                                            <Form.Control type="file" onChange={handleImageUpload} />
                                            {image && (
                                                <Card className="mt-3">
                                                    <Card.Img variant="top" src={URL.createObjectURL(image)} />
                                                    <Card.Body>
                                                        <Card.Title>{imageName}</Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </div>
                                    </Form.Group>

                                    {/* Sample Images */}
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="sampleImage1">
                                                <Form.Label>Sample Image 1</Form.Label>
                                                <div>
                                                    {product.sample_image1 && typeof product.sample_image1 === 'string' && (
                                                        <img src={product.sample_image1} alt="Sample Image 1" className="product-list-images" />
                                                    )}
                                                    <Form.Control
                                                        type="file"
                                                        name="sample_image1"
                                                        onChange={(e) => handleSampleImageUpload(e, 'sample_image1')}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="sampleImage2">
                                                <Form.Label>Sample Image 2</Form.Label>
                                                <div>
                                                    {product.sample_image2 && typeof product.sample_image2 === 'string' && (
                                                        <img src={product.sample_image2} alt="Sample Image 2" className="product-list-images" />
                                                    )}
                                                    <Form.Control
                                                        type="file"
                                                        name="sample_image2"
                                                        onChange={(e) => handleSampleImageUpload(e, 'sample_image2')}
                                                    />
                                                </div>
                                            </Form.Group>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="sampleImage3">
                                                <Form.Label>Sample Image 3</Form.Label>
                                                <div>
                                                    {product.sample_image3 && typeof product.sample_image3 === 'string' && (
                                                        <img src={product.sample_image3} alt="Sample Image 3" className="product-list-images" />
                                                    )}
                                                    <Form.Control
                                                        type="file"
                                                        name="sample_image3"
                                                        onChange={(e) => handleSampleImageUpload(e, 'sample_image3')}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="sampleImage4">
                                                <Form.Label>Sample Image 4</Form.Label>
                                                <div>
                                                    {product.sample_image4 && typeof product.sample_image4 === 'string' && (
                                                        <img src={product.sample_image4} alt="Sample Image 4" className="product-list-images" />
                                                    )}
                                                    <Form.Control
                                                        type="file"
                                                        name="sample_image4"
                                                        onChange={(e) => handleSampleImageUpload(e, 'sample_image4')}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                    {/* Video URL */}
                                    <Form.Group controlId="video">
                                        <Form.Label>Video</Form.Label>
                                        <div>
                                            {product.video && (
                                                <video width="320" height="240" controls>
                                                    <source src={videoPreview || `${product.video}`} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            )}
                                            <Form.Control
                                                type="file"
                                                accept="video/*"
                                                placeholder="Upload video"
                                                name="video"
                                                onChange={handleVideoUpload}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="packageWeight">
                                                <Form.Label>Package Weight</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter package weight"
                                                    value={product.packageWeight || ''}
                                                    onChange={(e) => setProduct({ ...product, packageWeight: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="packageHeight">
                                                <Form.Label>Package Height</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter package height"
                                                    value={product.packageheight || ''}
                                                    onChange={(e) => setProduct({ ...product, packageheight: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="packageLength">
                                                <Form.Label>Package Length</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter package length"
                                                    value={product.packagelength || ''}
                                                    onChange={(e) => setProduct({ ...product, packagelength: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="packageWidth">
                                                <Form.Label>Package Width</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter package width"
                                                    value={product.packagewidth || ''}
                                                    onChange={(e) => setProduct({ ...product, packagewidth: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="fabricType">
                                                <Form.Label>Fabric Type</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter fabric type"
                                                    value={product.fabrictype || ''}
                                                    onChange={(e) => setProduct({ ...product, fabrictype: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="manufacturer">
                                                <Form.Label>Manufactured By</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter manufacturer"
                                                    value={product.manufaturedby || ''}
                                                    onChange={(e) => setProduct({ ...product, manufaturedby: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="washCare">
                                                <Form.Label>Wash Care</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter wash care"
                                                    value={product.washcare || ''}
                                                    onChange={(e) => setProduct({ ...product, washcare: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="fit">
                                                <Form.Label>Fit</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter fit"
                                                    value={product.fit || ''}
                                                    onChange={(e) => setProduct({ ...product, fit: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="gender">
                                                <Form.Label>Gender</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={product.gender || ''}
                                                    onChange={(e) => setProduct({ ...product, gender: e.target.value })}
                                                >
                                                    <option value="">Select gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Unisex">Unisex</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="skqNumber">
                                                <Form.Label>SKU Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter SKQ number"
                                                    value={product.skq_number || ''}
                                                    onChange={(e) => setProduct({ ...product, skq_number: e.target.value })}
                                                    style={inputStyle} 
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <button variant="primary" type="submit">
                                        Update Product
                                    </button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
}

export default EditProduct;

