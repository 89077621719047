import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from 'multiselect-react-dropdown';
import { v4 as uuidv4 } from 'uuid';
import "../assets/css/addproduct.css";

function Comboproduct() {
    const [itemName, setItemName] = useState("");
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [dimensions, setDimensions] = useState("");
    const [price, setPrice] = useState("");
    const [discount, setDiscount] = useState("");
    const [stock, setStockQty] = useState("");
    const [isFeatured, setIsFeatured] = useState(false);
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [sampleImages, setSampleImages] = useState(new Array(4).fill(null));
    const [tax, setTax] = useState("");
    const [packageWeight, setPackageWeight] = useState("");
    const [packageHeight, setPackageHeight] = useState("");
    const [packageLength, setPackageLength] = useState("");
    const [packageWidth, setPackageWidth] = useState("");
    const [gender, setGender] = useState("");
    const [skqnumber, setSkqnumber] = useState("");
    const [noOfPieces, setNoOfPieces] = useState("");
    const [isAvailable, setIsAvailable] = useState(false);
    const [videoFile, setVideoFile] = useState(null);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [comboProductIDs, setComboProductIDs] = useState([]);
    const [products, setProducts] = useState([]);
    const [comboProductOptions, setComboProductOptions] = useState([]);
    const [label, setLabel] = useState("");
    const [colorID, setColorID] = useState("");
    const [colors, setColors] = useState([]);
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");
    const [size, setSize] = useState("");
    const [orderIndex, setOrderIndex] = useState("");

    useEffect(() => {
        fetchCategories();
        fetchProducts();
        fetchColors();
    }, []);

    const fetchCategories = async () => {
        try {
            const url = `https://api.rollerfashions.com/api/Category/List_Category`;
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const fetchSubCategories = async (categoryID) => {
        try {
            const url = `https://api.rollerfashions.com/api/SubCategory/List_SubCategory?Category_ID=${categoryID}&Token=${token}`;
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            setSubCategories(response.data);
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };

    const fetchProducts = async () => {
        try {
            const url = `https://api.rollerfashions.com/api/Product/List_Product`;
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            setComboProductOptions(response.data);
        } catch (error) {
            console.error("Error fetching combo products:", error);
        }
    };

    const fetchColors = async () => {
        try {
            const url = `https://api.rollerfashions.com/api/Color/List_Color`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            setColors(response.data);
        } catch (error) {
            console.error("Error fetching colors:", error);
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result.split(',')[1]);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const imageBase64 = image ? await convertToBase64(image) : "";
        const sampleImagesBase64 = await Promise.all(sampleImages.map(img => img ? convertToBase64(img) : ""));
        
        const formdata = {
            name: itemName,
            category_ID: category,
            subcategory_ID: subCategory,
            dimensions: dimensions,
            price: price,
            discount: discount,
            stock: stock,
            isfeature: isFeatured,
            description: description,
            display_image: `data:image/jpeg;base64,${imageBase64}`,
            sample_image1: `data:image/jpeg;base64,${sampleImagesBase64[0]}`,
            sample_image2: `data:image/jpeg;base64,${sampleImagesBase64[1]}`,
            sample_image3: `data:image/jpeg;base64,${sampleImagesBase64[2]}`,
            sample_image4: `data:image/jpeg;base64,${sampleImagesBase64[3]}`,
            Tax: tax,
            packageWeight: packageWeight,
            packageheight: packageHeight,
            packagelength: packageLength,
            packagewidth: packageWidth,
            gender: gender,
            skq_number: skqnumber,
            noof_pieces: noOfPieces,
            isavailable: isAvailable,
            comboproduct_ID: comboProductIDs.join(','),
            author_ID: authorid,
            label: label,
            combo_ID: [
                {
                    product_ID: uuidv4(), 
                    product_Size: [
                        {
                            size: size,
                            color: colorID,
                            order_Index: orderIndex
                        }
                    ]
                }
            ],
        };

        const resetForm = () => {
            setItemName("");
            setCategory("");
            setSubCategory("");
            setDimensions("");
            setPrice("");
            setDiscount("");
            setStockQty("");
            setIsFeatured(false);
            setDescription("");
            setImage(null);
            setSampleImages(new Array(4).fill(null));
            setTax("");
            setPackageWeight("");
            setPackageHeight("");
            setPackageLength("");
            setPackageWidth("");
            setGender("");
            setSkqnumber("");
            setNoOfPieces("");
            setIsAvailable(false);
            setVideoFile(null);
            setComboProductIDs([]);
            setLabel("");
            setLabel("");
            setColorID("");
            setSize("");
            setOrderIndex("");
        };

        console.log('Request payload:', formdata);

        try {
            const response = await axios.post('https://api.rollerfashions.com/api/Combo_product/Create_comboproduct', formdata, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            console.log(response.data);
            toast.success('Product created successfully');
            resetForm();
        } catch (error) {
            console.error("Error creating product:", error);
            if (error.response && error.response.data && error.response.data.errors) {
                const validationErrors = error.response.data.errors;
                for (const [field, messages] of Object.entries(validationErrors)) {
                    messages.forEach(message => toast.error(`${field}: ${message}`));
                }
            } else {
                toast.error('Failed to create product');
            }
        }
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size <= 102400) {
                setImage(file);
            } else {
                toast.error("Image size should be less than or equal to 100KB");
            }
        }
    };

    const handleSampleImageUpload = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size <= 102400) {
                const updatedSampleImages = [...sampleImages];
                updatedSampleImages[index] = file;
                setSampleImages(updatedSampleImages);
            } else {
                toast.error("Image size should be less than or equal to 100KB");
            }
        }
    };

    const handleVideoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setVideoFile(file);
        }
    };

    return (
        <div className="container-fluid content-wrapper addproduct-container">
            <div className="card addproduct-card">
                <div className="card-body">
                    <h2>Add Combo Product</h2>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="itemName">
                                    <Form.Label>Item Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter item name" value={itemName} onChange={(e) => setItemName(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="category">
                                    <Form.Label>Category</Form.Label>
                                    <Form.Control as="select" value={category} onChange={(e) => {
                                        setCategory(e.target.value);
                                        fetchSubCategories(e.target.value);
                                    }}>
                                        <option value="">Select Category</option>
                                        {categories.map(cat => (
                                            <option key={cat.id} value={cat.id}>{cat.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="subCategory">
                                    <Form.Label>Subcategory</Form.Label>
                                    <Form.Control as="select" value={subCategory} onChange={(e) => setSubCategory(e.target.value)}>
                                        <option value="">Select Subcategory</option>
                                        {subCategories.map(subCat => (
                                            <option key={subCat.id} value={subCat.id}>{subCat.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="dimensions">
                                    <Form.Label>Dimensions</Form.Label>
                                    <Form.Control type="text" placeholder="Enter dimensions" value={dimensions} onChange={(e) => setDimensions(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="price">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control type="text" placeholder="Enter price" value={price} onChange={(e) => setPrice(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="discount">
                                    <Form.Label>Discount</Form.Label>
                                    <Form.Control type="text" placeholder="Enter discount" value={discount} onChange={(e) => setDiscount(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="stock">
                                    <Form.Label>Stock Quantity</Form.Label>
                                    <Form.Control type="text" placeholder="Enter stock quantity" value={stock} onChange={(e) => setStockQty(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="isFeatured">
                                    <Form.Label>Featured</Form.Label>
                                    <Form.Check type="checkbox" label="Is Featured" checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Display Image (Max 100KB)</Form.Label>
                                    <Form.Control type="file" accept="image/*" onChange={handleImageUpload} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="sampleImages">
                                    <Form.Label>Sample Images (Max 100KB each)</Form.Label>
                                    <div className="d-flex flex-wrap">
                                        {sampleImages.map((img, index) => (
                                            <div key={index} className="me-2">
                                                <Form.Control type="file" accept="image/*" onChange={(e) => handleSampleImageUpload(e, index)} />
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="tax">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Control type="text" placeholder="Enter tax" value={tax} onChange={(e) => setTax(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="packageWeight">
                                    <Form.Label>Package Weight</Form.Label>
                                    <Form.Control type="text" placeholder="Enter package weight" value={packageWeight} onChange={(e) => setPackageWeight(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="packageHeight">
                                    <Form.Label>Package Height</Form.Label>
                                    <Form.Control type="text" placeholder="Enter package height" value={packageHeight} onChange={(e) => setPackageHeight(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="packageLength">
                                    <Form.Label>Package Length</Form.Label>
                                    <Form.Control type="text" placeholder="Enter package length" value={packageLength} onChange={(e) => setPackageLength(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="packageWidth">
                                    <Form.Label>Package Width</Form.Label>
                                    <Form.Control type="text" placeholder="Enter package width" value={packageWidth} onChange={(e) => setPackageWidth(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="gender">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Control as="select" value={gender} onChange={(e) => setGender(e.target.value)}>
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="skqnumber">
                                    <Form.Label>SKQ Number</Form.Label>
                                    <Form.Control type="text" placeholder="Enter SKQ number" value={skqnumber} onChange={(e) => setSkqnumber(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="noOfPieces">
                                    <Form.Label>No. of Pieces</Form.Label>
                                    <Form.Control type="text" placeholder="Enter number of pieces" value={noOfPieces} onChange={(e) => setNoOfPieces(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="isAvailable">
                                    <Form.Label>Available</Form.Label>
                                    <Form.Check type="checkbox" label="Is Available" checked={isAvailable} onChange={(e) => setIsAvailable(e.target.checked)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="label">
                                    <Form.Label>Label</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Label" value={label} onChange={(e) => setLabel(e.target.value)} />
                                </Form.Group>
                            </Col>
                            {/* <Col md={6}>
                                <Form.Group className="mb-3" controlId="stock">
                                    <Form.Label>Stock</Form.Label>
                                    <div className="d-flex">
                                        {['xs', 's', 'm', 'l', 'xl', 'xxl'].map(size => (
                                            <div key={size} className="me-2">
                                                <Form.Control type="text" placeholder={size.toUpperCase()} value={stock[size]} onChange={(e) => handleStockChange(e, size)} />
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>
                            </Col> */}
                        </Row>
                        <Row>

                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="comboProductIDs">
                                    <Form.Label>Combo Products</Form.Label>
                                    <Multiselect
                                        options={comboProductOptions}
                                        selectedValues={comboProductOptions.filter(option => comboProductIDs.includes(option.id))}
                                        onSelect={(selectedList, selectedItem) => {
                                            setComboProductIDs(selectedList.map(item => item.id));
                                        }}
                                        onRemove={(selectedList, removedItem) => {
                                            setComboProductIDs(selectedList.map(item => item.id));
                                        }}
                                        displayValue="name"
                                        placeholder="Select Combo Products"
                                        showCheckbox
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="colorID">
                                    <Form.Label>Color</Form.Label>
                                    <Form.Control as="select" value={colorID} onChange={(e) => setColorID(e.target.value)}>
                                        <option value="">Select Color</option>
                                        {colors.map(color => (
                                            <option key={color.id} value={color.id}>{color.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="size">
                                    <Form.Label>Size</Form.Label>
                                    <Form.Control as="select" value={size} onChange={(e) => setSize(e.target.value)}>
                                        <option value="">Select Size</option>
                                        <option value="s">S</option>
                                        <option value="m">M</option>
                                        <option value="l">L</option>
                                        <option value="xl">XL</option>
                                        <option value="xxl">XXL</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="orderIndex">
                                    <Form.Label>Order Index</Form.Label>
                                    <Form.Control as="select" value={orderIndex} onChange={(e) => setOrderIndex(e.target.value)}>
                                        <option value="">Select Order Index</option>
                                        {[1, 2, 3, 4, 5, 6].map(index => (
                                            <option key={index} value={index}>{index}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>

                                <Form.Group className="mb-3" controlId="videoFile">
                                    <Form.Label>Upload Video</Form.Label>
                                    <Form.Control type="file" accept="video/*" onChange={handleVideoUpload} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button variant="primary" type="submit">Submit</Button>
                    </Form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Comboproduct;
