import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const EditFitType = () => {
  const { fitId } = useParams(); // Get the Fit ID from the URL
  const [fitName, setFitName] = useState('');
  const [description, setDescription] = useState('');
  const [flexibility, setFlexibility] = useState('');
  const [bodyType, setBodyType] = useState('');
  const token = localStorage.getItem('responsetoken');
  const authorId = localStorage.getItem('authorid');
  const navigate = useNavigate(); // For redirecting after successful update
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchFitDetails();
  }, []);

  const fetchFitDetails = async () => {
    try {
      const response = await axios.get(`https://api.rollerfashions.com/api/Fit_type/Get_fittype?Fit_ID=${fitId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('API Response:', response.data); // Log the response

      const fitData = response.data;
      if (fitData) {
        setFitName(fitData.fitname);
        setDescription(fitData.description);
        setFlexibility(fitData.flexibility);
        setBodyType(fitData.bodytype);
      } else {
        toast.error('No fit data found');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching fit details:', error);
      toast.error('Failed to fetch fit details');
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate input fields
    if (!fitName || !description || !flexibility || !bodyType) {
      toast.error('Please fill in all required fields');
      return;
    }

    // Construct the object for the update request
    const updatedFitTypeData = {
      id: fitId,
      fitname: fitName,
      description: description,
      flexibility: flexibility,
      bodytype: bodyType,
      author_ID: authorId,
    };

    try {
      const response = await axios.post(
        'https://api.rollerfashions.com/api/Fit_type/Edit_fittype',
        updatedFitTypeData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.data.responseCode === 1) {
        toast.success('Fit Type updated successfully!');
        // Redirect to another page or reset the form
        navigate('/fittypelistpe'); // Redirect after successful update
      } else {
        toast.error('Failed to update Fit Type');
      }
    } catch (error) {
      console.error('Error updating Fit Type:', error);
      toast.error('An error occurred while updating Fit Type');
    }
  };

  return (
    <Container className="container-fluid editfit editfit-container">
      <h2 className="coupon-title mb-4">Edit Fit Type</h2>
      {loading ? (
        <p>Loading...</p> // or you can use a spinner here
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formFitName">
            <Form.Label>Fit Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter fit name"
              value={fitName}
              onChange={(e) => setFitName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formFlexibility">
            <Form.Label>Flexibility</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter flexibility"
              value={flexibility}
              onChange={(e) => setFlexibility(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBodyType">
            <Form.Label>Body Type</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter body type"
              value={bodyType}
              onChange={(e) => setBodyType(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Update Fit Type
          </Button>
        </Form>
      )}
      <ToastContainer />
    </Container>
  );
};

export default EditFitType;
